import React from "react";
import { graphql } from "gatsby";

import Article from "../../components/article";

const GuideTemplate = ({ data }) => {
  const {
    title,
    image,
    childContentfulGuideDescriptionTextNode: {
      childMarkdownRemark: { html },
    },
  } = data.contentfulGuide;
  return <Article title={title} image={image} html={html} />;
};

export const query = graphql`
  query getSingleGuide($title: String) {
    contentfulGuide(title: { eq: $title }) {
      childContentfulGuideDescriptionTextNode {
        childMarkdownRemark {
          html
        }
      }
      title
      image {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
  }
`;

export default GuideTemplate;
